import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

export const HEAT_PUMP_LOCALES = {
	WOLTAIR_CZ: 'tepelna-cerpadla',
	WOLTAIR_PL: 'pompy-ciepla',
	WOLTAIR_DE: 'waermepumpen',
	WOLTAIR_IT: 'pompe-di-calore'
}

export const HEAT_PUMP = HEAT_PUMP_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return HEAT_PUMP === param
}) satisfies ParamMatcher
